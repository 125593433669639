import React, {Component, Suspense,} from 'react'
import {
  Route,
  BrowserRouter as Router,
  Routes, Navigate
} from "react-router-dom";
import MainLayout from "./layout"
import frontMenu from "./routes/frontRoutes"
import ScrollToTop from "./web/scrollTopComponent";

class App extends Component {
  constructor(props) {
    super(props)

  }

  render() {
    return (
        <>
          <Router>
            <ScrollToTop />
            <Routes>
              <Route path={"/"} element={<MainLayout/>}>
                {frontMenu.map((item) => {
                  let {component: Component, path} = item
                  return (
                      <Route path={path} element={<Component/>}/>
                  )
                })}
              </Route>
            </Routes>
          </Router>

        </>
    )
  }
}

export default App
