import React, { useState } from "react";

const ForgotPassword = () => {
    const [emailOrMobile, setEmailOrMobile] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");

    const handleEmailOrMobileChange = (e) => {
        setEmailOrMobile(e.target.value);
    };

    const handleMobileNumberChange = (e) => {
        setMobileNumber(e.target.value);
    };

    const handleEmailOrMobileSubmit = (e) => {
        e.preventDefault();
        // Handle email/mobile submission logic
    };

    const handleMobileSubmit = (e) => {
        e.preventDefault();
        // Handle mobile number submission logic
    };

    return (
        <main className="flex-shrink-0">
            <section className="login-module">
                <div className="container">
                    <div className="main-head py-3 py-lg-5">
                        <h3>Get Started Now!</h3>
                        <p className="m-0">Enter Credentials to get started now!</p>
                    </div>
                </div>
            </section>
            <section className="form-section">
                <img src="/images/forgot-banner.jpg" className="banner-login"/>
                <div className="container">
                    <div className="row row-gap-3">
                        <div className="col-lg-7 me-auto">
                            <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                                <div className="head text-center mb-4">
                                    <h3 className="mb-2">Forgot your Password?</h3>
                                    <p className="m-0">Enter your Email or Mobile and we'll help you reset your
                                        password.</p>
                                </div>
                                <form onSubmit={handleEmailOrMobileSubmit}>
                                    <div className="mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Email or Mobile Number to get Instructions"
                                            value={emailOrMobile}
                                            onChange={handleEmailOrMobileChange}
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Continue</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="download-application spacing-60">
                <div className="container">
                    <div className="card pt-3 pt-lg-5">
                        <div className="col-lg-11 col-11 mx-auto">
                            <div className="row row-gap-3">
                                <div className="col-lg-7 me-auto col-12">
                                    <div className="content">
                                        <h3 className="text-white mb-2 mb-lg-4">Download Our Mobile Application</h3>
                                        <p>Book the flight tickets with huge discounts. <br /> Refer friends and get generous bonuses from their orders.</p>
                                        <p>Enter your phone number to get the download link</p>
                                        <div className="flex-box">
                                            <form onSubmit={handleMobileSubmit}>
                                                <div className="mb-2">
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Enter Mobile Number"
                                                        id="exampleInputNumber"
                                                        value={mobileNumber}
                                                        onChange={handleMobileNumberChange}
                                                    />
                                                </div>
                                                <button type="submit" className="btn btn-secondary">Send Download Link</button>
                                            </form>
                                            <span className="or">or</span>
                                            <div className="download-btns">
                                                <a href="#"><img src="../images/app-store.jpg" alt="App Store"/></a>
                                                <a href="#"><img src="../images/google-play.jpg" alt="Google Play"/></a>
                                            </div>
                                            <div className="scanner">
                                                <img src="../images/scanner.jpg" alt="QR Code Scanner"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-lg-block d-none">
                                    <img src="../images/mobile.png" alt="Mobile" className="w-100"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default ForgotPassword;
