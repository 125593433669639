import Home from "../web/home";
import Agents from "../web/agents";
import AboutUs from "../web/aboutUs";
import Events from "../web/events";
import ContactUs from "../web/contactUs";
import Login from "../web/login";
import Register from "../web/register";
import OtpVerification from "../web/otp-verification";
import ForgotPassword from "../web/forgotPassword";

let menu = [
    {
        name: "Home",
        title: "Home",
        component: Home,
        path: "/"
    },
    {
        name: "Home",
        title: "Home",
        component: Home,
        path: "/home"
    },
    {
        name: "Agents",
        title: "Agents",
        component: Agents,
        path: "/agents"
    },
    {
        name: "About Us",
        title: "About Us",
        component: AboutUs,
        path: "/about-us"
    },
    {
        name: "Events",
        title: "Events",
        component: Events,
        path: "/events"
    },
    {
        name: "Contact Us",
        title: "Contact Us",
        component: ContactUs,
        path: "/contact-us"
    },
    {
        name: "Login",
        title: "Login",
        component: Login,
        path: "/login"
    },
    {
        name: "Register",
        title: "Register",
        component: Register,
        path: "/register"
    },
    {
        name: "Otp Verification",
        title: "Otp Verification",
        component: OtpVerification,
        path: "/otpVerification"
    },
    {
        name: "Forgot Password",
        title: "Forgot Password",
        component: ForgotPassword,
        path: "/forgot-password"
    }
]

export default menu
