import React from "react";

const Login = () => {
    return (
        <>
            {/* Begin page content */}
            <main className="flex-shrink-0">
                <section className="login-module">
                    <div className="container">
                        <div className="main-head py-3 py-lg-5">
                            <h3>Get Started Now!</h3>
                            <p className="m-0">Enter Credentials to get started now!</p>
                        </div>
                    </div>
                </section>
                <section className="form-section">
                    <img src="../images/login-banner.jpg" className="banner-login"/>
                    <div className="container">
                        <div className="row row-gap-3">
                            <div className="col-lg-7 me-auto">
                                <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputCompanyName" className="form-label">Company
                                                Name</label>
                                            <input type="text" className="form-control" id="exampleInputCompanyName"
                                                   aria-describedby="CompanyName"/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                            <input type="password" className="form-control" id="exampleInputPassword1" />
                                        </div>
                                        <div className="mb-3 form-check">
                                            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                            <label className="form-check-label" htmlFor="exampleCheck1">Remember Me</label>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                        <div className="mt-3 d-flex flex-wrap gap-3">
                                            <a href="forgot-password" className="text-decoration-none text-black fw-500">Forgot Password</a>
                                            <a href="register" className="ms-sm-auto text-decoration-none text-black fw-500">Create New Account</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="download-application spacing-60">
                    <div className="container">
                        <div className="card pt-3 pt-lg-5">
                            <div className="col-lg-11 col-11 mx-auto">
                                <div className="row row-gap-3">
                                    <div className="col-lg-7 me-auto col-12">
                                        <div className="content">
                                            <h3 className="text-white mb-2 mb-lg-4">Download Our Mobile Application</h3>
                                            <p>Book the flight tickets with huge discounts. <br /> Refer friends and get generous bonuses from their orders.</p>
                                            <p>Enter your phone number to get the download link</p>
                                            <div className="flex-box">
                                                <form>
                                                    <div className="mb-2">
                                                        <input type="number" className="form-control" placeholder="Enter Mobile Number" id="exampleInputNumber" />
                                                    </div>
                                                    <button type="submit" className="btn btn-secondary">Send Download Link</button>
                                                </form>
                                                <span className="or">or</span>
                                                <div className="download-btns">
                                                    <a href="#"><img src="../images/app-store.jpg" alt="app-store"/></a>
                                                    <a href="#"><img src="../images/google-play.jpg" alt="google-play"/></a>
                                                </div>
                                                <div className="scanner">
                                                    <img src="../images/scanner.jpg" alt="scanner"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-lg-block d-none">
                                        <img src="../images/mobile.png" alt="mobile" className="w-100"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Login;
