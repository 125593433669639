import React from "react";
import Slider from "react-slick";

const Events = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    return (
        <>
            {/* Begin page content */}
            <main className="flex-shrink-0">
                <section className="about-banner">
                    <div className="spacing-60">
                        <div className="container">
                            <div className="col-lg-10 mx-auto col-12">
                                <div className="row row-gap-3 align-items-center">
                                    <div className="col-lg-5 me-auto col-12">
                                        <h2 className="m-0">Stay Connected with the Latest Events</h2>
                                    </div>
                                    <div className="col-lg-6 col-12">
                                        <p className="m-0">Connecting Travel Agents with Seamless Flight Booking
                                            Solutions Worldwide</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <img src="/images/event-banner.jpg" className="w-100"/>
                </section>

                <section className="note-banner pt-lg-4 pt-3">
                    <div className="container">
                        <h3 className="m-0">Stay Connected with the Latest Events</h3>
                    </div>
                </section>

                <section className="events spacing-60 pb-0">
                    <div className="container">
                        <div className="head text-center mb-5">
                            <h3>Our Events</h3>
                        </div>

                        <div className="row row-gap-4">
                            {/* Event Card */}
                            {[...Array(9)].map((_, index) => (
                                <div className="col-lg-4 col-12" key={index}>
                                    <div className="card border-0 p-0">
                                        <span>
                                            <img
                                                src="https://images.pexels.com/photos/716276/pexels-photo-716276.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                alt={`Event ${index + 1}`}
                                            />
                                        </span>
                                        <h5>
                                            <a href="#">Event Name {index + 1}</a>
                                        </h5>
                                        <p className="m-0">
                                            <img src="../images/calendar-icon.png" alt="calendar icon"/> 23rd September,
                                            2024
                                        </p>
                                        <p className="m-0">
                                            <img src="../images/time-icon.png" alt="time icon"/> 10:00 AM onwards
                                        </p>
                                        <p className="m-0">
                                            <img src="../images/location-icon.png" alt="location icon"/> London, UK
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="download-application spacing-60">
                    <div className="container">
                        <div className="card pt-3 pt-lg-5">
                            <div className="col-lg-11 col-11 mx-auto">
                                <div className="row row-gap-3">
                                    <div className="col-lg-7 me-auto col-12">
                                        <div className="content">
                                            <h3 className="text-white mb-2 mb-lg-4">Download Our Mobile Application</h3>
                                            <p>
                                                Book flight tickets with huge discounts. <br /> Refer friends and get generous bonuses from their orders.
                                            </p>
                                            <p>Enter your phone number to get the download link:</p>
                                            <div className="flex-box">
                                                <form>
                                                    <div className="mb-2">
                                                        <input type="number" className="form-control" placeholder="Enter Mobile Number" id="exampleInputNumber" />
                                                    </div>
                                                    <button type="submit" className="btn btn-secondary">Send Download Link</button>
                                                </form>
                                                <span className="or">or</span>
                                                <div className="download-btns">
                                                    <a href="#"><img src="../images/app-store.jpg"
                                                                     alt="Download on the App Store"/></a>
                                                    <a href="#"><img src="../images/google-play.jpg"
                                                                     alt="Get it on Google Play"/></a>
                                                </div>
                                                <div className="scanner">
                                                    <img src="../images/scaner.jpg" alt="QR code scanner"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-lg-block d-none">
                                        <img src="../images/mobile.png" alt="Mobile App" className="w-100"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="review">
                    <div className="container">
                        <div className="row row-gap-3">
                            <div className="col-lg-4 col-12">
                                <div className="star-box mb-lg-4 mb-3">
                                    <ul>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                    </ul>
                                </div>
                                <div className="head text-start">
                                    <h3 className="mb-2">What our Client Reviews?</h3>
                                    <p className="m-0">We take pride in delivering exceptional service and memorable
                                        travel experiences. Here's what our clients have to say about their journeys
                                        with us:</p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-12">
                                <div className="review-slide">
                                    <Slider {...settings}>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                                <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                    labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                    nobis.</p>
                                                <a href="#">Read more...</a>
                                                <h3 className={'text-white'}>Arun K.</h3>
                                            </div>
                                        </div>
                                    </Slider>

                                </div>
                                <div className="text-end" style={{marginTop: '19px'}}>
                                    <a href="#" className="btn text-decoration-none text-white fw-500 btn-primary">View
                                        All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bonus spacing-60">
                    <div className="container">
                        <section className="note-banner">
                            <h3 className="m-0 text-uppercase rounded-2"><a href="register">Register Now!</a></h3>
                        </section>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Events;
