import React from "react";

const Register = () => {
    return (
        <>
            <main className="flex-shrink-0">
                <section className="login-module">
                    <div className="container">
                        <div className="main-head py-3 py-lg-5">
                            <h3>Welcome Back</h3>
                            <p className="m-0">Please login to your account</p>
                        </div>
                    </div>
                </section>
                <section className="form-section">
                    <img src="../images/register-banner.jpg" className="banner-login"/>
                    <div className="container">
                        <div className="row row-gap-3">
                            <div className="col-lg-7 me-auto">
                                <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                                    <form>
                                        <div className="row row-gap-3">
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">Company Name</label>
                                                <input type="text" className="form-control"
                                                       placeholder="Your Company Name"/>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">Person Name</label>
                                                <input type="text" className="form-control" placeholder="Your First Name" />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">Country</label>
                                                <select name="country" id="country" className="form-control">
                                                    <option value="1">Country 1</option>
                                                    <option value="2">Country 2</option>
                                                    <option value="3">Country 3</option>
                                                    <option value="4">Country 4</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">State</label>
                                                <select name="state" id="state" className="form-control">
                                                    <option value="1">State 1</option>
                                                    <option value="2">State 2</option>
                                                    <option value="3">State 3</option>
                                                    <option value="4">State 4</option>
                                                </select>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">City</label>
                                                <input type="text" className="form-control" placeholder="Enter City Name" />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">Postal Code</label>
                                                <input type="text" className="form-control" placeholder="Enter Postal Code" />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">Phone Number</label>
                                                <input type="tel" className="form-control" placeholder="Enter Phone Number" />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">Email</label>
                                                <input type="email" className="form-control" placeholder="Enter Email" />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">Website</label>
                                                <input type="url" className="form-control" placeholder="Enter Website" />
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <label className="form-label">Referral Code</label>
                                                <input type="text" className="form-control" placeholder="Referral Code (Optional)" />
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label">Address</label>
                                                <input type="text" className="form-control" placeholder="Complete Address" />
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-4">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="download-application spacing-60">
                    <div className="container">
                        <div className="card pt-3 pt-lg-5">
                            <div className="col-lg-11 col-11 mx-auto">
                                <div className="row row-gap-3">
                                    <div className="col-lg-7 me-auto col-12">
                                        <div className="content">
                                            <h3 className="text-white mb-2 mb-lg-4">Download Our Mobile Application</h3>
                                            <p>Book the flight tickets with huge discounts. <br /> Refer friends and get generous bonuses from their orders.</p>
                                            <p>Enter your phone number to get the download link</p>
                                            <div className="flex-box">
                                                <form>
                                                    <div className="mb-2">
                                                        <input type="tel" className="form-control" placeholder="Enter Mobile Number" id="exampleInputNumber" />
                                                    </div>
                                                    <button type="submit" className="btn btn-secondary">Send Download Link</button>
                                                </form>
                                                <span className="or">or</span>
                                                <div className="download-btns">
                                                    <a href="#"><img src="../images/app-store.jpg" alt="app-store"/></a>
                                                    <a href="#"><img src="../images/google-play.jpg" alt="google-play"/></a>
                                                </div>
                                                <div className="scaner">
                                                    <img src="../images/scaner.jpg" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-lg-block d-none">
                                        <img src="../images/mobile.png" alt="mobile" className="w-100"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Register;
