import React, { useState } from "react";

const OtpVerification = () => {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);

    const handleChange = (e, index) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value;

        // Move focus to the next input when a digit is entered
        if (e.target.value.length === 1 && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }

        setOtp(newOtp);
    };

    return (
        <>
            <main className="flex-shrink-0">
                <section className="login-module">
                    <div className="container">
                        <div className="main-head py-3 py-lg-5">
                            <h3>Get Started Now!</h3>
                            <p className="m-0">Enter Credentials to get started now!</p>
                        </div>
                    </div>
                </section>
                <section className="form-section">
                    <img src="../images/forgot-banner.jpg" className="banner-login"/>
                    <div className="container">
                        <div className="row row-gap-3">
                            <div className="col-lg-7 me-auto">
                                <div className="card rounded-0 shadow-none p-3 border-0 p-lg-5">
                                    <div className="head text-center mb-4">
                                        <h3 className="mb-2">OTP Verification</h3>
                                        <p className="m-0">Enter OTP Code sent to +91 9888******48</p>
                                    </div>

                                    <form>
                                        <div className="otp-field mb-4 d-flex justify-content-between">
                                            {otp.map((digit, index) => (
                                                <input
                                                    key={index}
                                                    type="text"
                                                    value={digit}
                                                    onChange={(e) => handleChange(e, index)}
                                                    id={`otp-input-${index}`}
                                                    maxLength={1}
                                                    className="otp-input"
                                                />
                                            ))}
                                        </div>

                                        <p className="resend mb-0 text-center">
                                            Didn't receive code?{" "}
                                            <a href="#" className="d-block fw-500 text-decoration-none" style={{ color: "#2196f3" }}>
                                                Resend Code
                                            </a>
                                        </p>

                                        <button type="submit" className="btn btn-primary mt-4">
                                            Verify & Proceed
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="download-application spacing-60">
                    <div className="container">
                        <div className="card pt-3 pt-lg-5">
                            <div className="col-lg-11 col-11 mx-auto">
                                <div className="row row-gap-3">
                                    <div className="col-lg-7 me-auto col-12">
                                        <div className="content">
                                            <h3 className="text-white mb-2 mb-lg-4">Download Our Mobile Application</h3>
                                            <p>Book the flight tickets with huge discounts. <br /> Refer friends and get generous bonuses from their orders.</p>
                                            <p>Enter your phone number to get the download link</p>
                                            <div className="flex-box">
                                                <form>
                                                    <div className="mb-2">
                                                        <input type="text" className="form-control" placeholder="Enter Mobile Number" id="exampleInputNumber" />
                                                    </div>
                                                    <button type="submit" className="btn btn-secondary">Send Download Link</button>
                                                </form>
                                                <span className="or">or</span>
                                                <div className="download-btns">
                                                    <a href="#"><img src="../images/app-store.jpg" alt="app-store"/></a>
                                                    <a href="#"><img src="../images/google-play.jpg" alt="google-play"/></a>
                                                </div>
                                                <div className="scaner">
                                                    <img src="../images/scaner.jpg" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-lg-block d-none">
                                        <img src="../images/mobile.png" alt="mobile" className="w-100"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default OtpVerification;
