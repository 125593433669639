import React from "react";
import Slider from "react-slick";

const Home = () => {
    var settings = {
        dots: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        centerMode: true,
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };
    return (
        <>
            <main className="flex-shrink-0">
                <div className="banner spacing-60">
                    <img src="../images/home-banner.jpg" className="w-100"/>
                    <div className="container position-relative">
                        <div className="col-lg-11 col-12 ms-auto">
                            <div className="row row-gap-3">
                                <div className="col-lg-5 me-auto">
                                    <h1>Powering Your Travel Business with Seamless Flight Bookings</h1>
                                    <p className="m-0">Access the Best Deals and Unmatched Flight Options at Your
                                        Fingertips</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="why-choose spacing-60">
                    <img src="../images/why-choose-banner.jpg" alt="Why Choose Banner"/>
                    <div className="container position-relative">
                        <div className="head text-center mb-5">
                            <h3 className={'text-white'}>Why Choose X Travel World?</h3>
                        </div>
                        <div className="col-lg-9 col-12 mx-auto">
                            <div className="row row-gap-4">
                                <div className="col-lg-4 col-12">
                                    <div className="content-box">
                                        <span><img src="../images/why-choose-icon-1.png"
                                                   alt="Exclusive Deals Icon"/></span>
                                        <h4 className={'text-white'}>Exclusive Deals</h4>
                                        <p className="m-0">Access to exclusive wholesale flight deals</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="content-box">
                                        <span><img src="../images/why-choose-icon-2.png"
                                                   alt="Real-Time Booking Icon"/></span>
                                        <h4 className={'text-white'}>Real-Time Booking</h4>
                                        <p className="m-0">Book flights in real-time with instant confirmation</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="content-box">
                                        <span><img src="../images/why-choose-icon-3.png"
                                                   alt="24/7 Support Icon"/></span>
                                        <h4 className={'text-white'}>24/7 Support</h4>
                                        <p className="m-0">Dedicated customer support to assist you anytime</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="note-banner pt-lg-4 pt-3">
                    <div className="container">
                        <h3 className="m-0">Trusted by thousands. Your Journey Starts Here!</h3>
                    </div>
                </section>

                <section className="agent-with-us spacing-60 pb-0">
                    <div className="container">
                        <div className="head text-center mb-5">
                            <h3>How to Register as an Agent with us?</h3>
                        </div>

                        <div className="row align-items-center">
                            <div className="col-lg-6 col-12">
                                <div className="grid">
                                    <div className="box p-lg-4 p-3 g-col-12 mb-3">
                                        <span><img src="../images/agent-icon-1.png" alt="Signup Icon"/></span>
                                        <div className="content-box">
                                            <h4>Signup as a Travel Agent</h4>
                                            <p>Locate the "Sign Up" button at the top right corner of the page and click
                                                on it.</p>
                                        </div>
                                    </div>
                                    <div className="box p-lg-4 p-3 g-col-12 mb-3">
                                        <span><img src="../images/agent-icon-2.png"
                                                   alt="Registration Form Icon"/></span>
                                        <div className="content-box">
                                            <h4>Fill Out the Registration Form</h4>
                                            <p>Complete the registration form with your details.</p>
                                        </div>
                                    </div>
                                    <div className="box p-lg-4 p-3 g-col-12 mb-3">
                                        <span><img src="../images/agent-icon-3.png"
                                                   alt="Submit Application Icon"/></span>
                                        <div className="content-box">
                                            <h4>Submit Your Application</h4>
                                            <p>Click the "Submit" button to send your registration form.</p>
                                        </div>
                                    </div>
                                    <div className="box p-lg-4 p-3 g-col-12">
                                        <span><img src="../images/agent-icon-4.png" alt="Start Booking Icon"/></span>
                                        <div className="content-box">
                                            <h4>Start Booking</h4>
                                            <p>Use your credentials to log in, access exclusive deals, and start booking
                                                flights instantly!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 position-sticky" style={{top: "150px"}}>
                                <div id="container" className="text-center">
                                    <div className="item">
                                        <img src="../images/logo-blue.png" alt="Logo"/>
                                    </div>
                                    <div className="circle"></div>
                                    <div className="circle" style={{animationDelay: "0s"}}></div>
                                    <div className="circle" style={{animationDelay: "1s"}}></div>
                                    <div className="circle" style={{animationDelay: "2s"}}></div>
                                    <div className="circle" style={{animationDelay: "3s"}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="download-application spacing-60">
                    <div className="container">
                        <div className="card pt-3 pt-lg-5">
                            <div className="col-lg-11 col-11 mx-auto">
                                <div className="row row-gap-3">
                                    <div className="col-lg-7 me-auto col-12">
                                        <div className="content">
                                            <h3 className="text-white mb-2 mb-lg-4">Download Our Mobile Application</h3>
                                            <p>Book the flight tickets with huge discounts. <br/> Refer friends and get
                                                generous bonuses from their orders.</p>
                                            <p>Enter your phone number to get the download link</p>
                                            <div className="flex-box">
                                                <form>
                                                    <div className="mb-2">
                                                        <input type="number" className="form-control"
                                                               placeholder="Enter Mobile Number"
                                                               id="exampleInputNumber"/>
                                                    </div>
                                                    <button type="submit" className="btn btn-secondary">Send Download
                                                        Link
                                                    </button>
                                                </form>
                                                <span className="or">or</span>
                                                <div className="download-btns">
                                                    <a href="#"><img src="../images/app-store.jpg"
                                                                     alt="Download on App Store"/></a>
                                                    <a href="#"><img src="../images/google-play.jpg"
                                                                     alt="Get it on Google Play"/></a>
                                                </div>
                                                <div className="scaner">
                                                    <img src="../images/scaner.jpg" alt="Scanner"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 d-lg-block d-none">
                                        <img src="../images/mobile.png" alt="Mobile App" className="w-100"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="review spacing-60 pt-0">
                    <div className="container">
                        <div className="row row-gap-3">
                            <div className="col-lg-4 col-12">
                                <div className="star-box mb-lg-4 mb-3">
                                    <ul>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                        <li><img src="../images/star.png" alt="star"/></li>
                                    </ul>
                                </div>
                                <div className="head text-start">
                                    <h3 className="mb-2">What our Client Reviews?</h3>
                                    <p className="m-0">We take pride in delivering exceptional service and memorable
                                        travel experiences. Here's what our clients have to say about their journeys
                                        with us:</p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-12">
                                <div className="review-slide">
                                    <Slider {...settings}>
                                    <div>
                                        <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                            <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                nobis.</p>
                                            <a href="#">Read more...</a>
                                            <h3 className={'text-white'}>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                            <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                nobis.</p>
                                            <a href="#">Read more...</a>
                                            <h3 className={'text-white'}>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                            <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                nobis.</p>
                                            <a href="#">Read more...</a>
                                            <h3 className={'text-white'}>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                            <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                nobis.</p>
                                            <a href="#">Read more...</a>
                                            <h3 className={'text-white'}>Arun K.</h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="review-box">
                  <span className="mb-3">
                    <ul>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                      <li><img src="../images/star.png" alt="star"/></li>
                    </ul>
                  </span>
                                            <h4 className={'text-white'}>Smooth Booking Experience!</h4>
                                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Laboriosam
                                                labore error quasi. Tempora adipisci omnis aperiam quae debitis iure
                                                nobis.</p>
                                            <a href="#">Read more...</a>
                                            <h3 className={'text-white'}>Arun K.</h3>
                                        </div>
                                    </div>
                                    </Slider>

                                </div>
                                <div className="text-end" style={{marginTop: '19px'}}>
                                    <a href="#" className="btn text-decoration-none text-white fw-500 btn-primary">View
                                        All</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="bonus spacing-60">
                        <div className="shape-circle"></div>
                        <div className="container">
                            <div className="box position-relative text-center py-lg-5 py-3">
                                <img
                                    src="https://images.pexels.com/photos/7683897/pexels-photo-7683897.jpeg?auto=compress&cs=tinysrgb&w=600"
                                    alt=""/>
                                <div className="overlay"></div>
                                <div className="position-relative z-1">
                                    <h3 className={'text-white'}>Book now and Earn 20% bonus Miles</h3>
                                    <p>Ready to make your next trip even more rewarding? <br/> With our limited-time
                                        offer, <br/>every booking brings you closer to your dream destination!</p>
                                </div>
                            </div>
                            <section className="note-banner">
                                <h3 className="m-0 text-uppercase"><a href="register">Register Now!</a></h3>
                            </section>
                        </div>
                    </section>
                </section>
            </main>
        </>
    );
};

export default Home;
